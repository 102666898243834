import React, { useEffect, useState } from 'react';
// import TheBunkerHomePageVideo from '../../assets/thebunker_home_video.mp4';
import { useHistory } from 'react-router-dom';
import TheBunkerHomePageVideo from '../../assets/Bunker_Homepage_Video_1920x1080.mp4';
import TheBunkerHomePageVideoMobile from '../../assets/Bunker_Homepage_Video_Mobile.mp4';
import BookEventsButton from '../../components/BookEventsButton/BookEventsButton';
import BookNowButton from '../../components/BookNowButton/BookNowButton.js';
import EventTicker from '../../components/EventTicker/Ticker.js';
import { withFirebase } from '../../components/Firebase';
import LocationSelector from '../../components/LocationSelection';
import MealeoButton from '../../components/MealeoButton';
import InfoModal from '../../components/Modal/modal';
import useWindowDimensions from '../../components/windowDimensionHook.js';
import useNoticeInfo from '../../hooks/useNoticeInfo';
import './index.scss';

export const Footer = () => (
  <div className="footer m-0 p-3">
    <p className="font-weight-bold">
      ©2024 The Bunker. All Rights Reserved.
    </p>
  </div>
);

const Notice = ({ title, message, link, linkText }) => (
  <div className="d-flex flex-column">
    <div className="bg-primary text-white text-center p-3">
      <p className="desc subtitle mb-0 font-weight-bold">{title}</p>
      {message && <p className="subtitle mb-0">{message}</p>}
      {link && (
        <div className="d-flex justify-content-center mt-2">
          <a href={link} target="_blank" className="btn btn-secondary">
            {linkText || link || "Click Here"}
          </a>
        </div>
      )}
    </div>
  </div>
);


const Landing = ({ firebase }) => {
  const { setShowInfoModal, noticeInfo } = useNoticeInfo(firebase);

  const [items, setItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const dimensions = useWindowDimensions();

	const ticker_width_space = dimensions.width > 992 ? 300 : 0;


  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await firebase.calendarEventsAll().get();
      let allEvents = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
				console.log(data)
        const filteredEvents = data.calendarEvents?.filter(
          (event) => event.showOnFeed,
        );
        if (filteredEvents) {
          allEvents = allEvents.concat(filteredEvents);
        }
      });

      // Sort events by timestamp
      allEvents.sort(
        (a, b) => a.timestamp.seconds - b.timestamp.seconds,
      );

      console.log(allEvents);
      setItems(allEvents);
    };

    fetchEvents();
  }, [firebase]);

  return (
    <div className="d-flex flex-column m-0 p-0">
      {noticeInfo?.noticeMsg &&
        noticeInfo?.noticeMsg?.showNoticeMsg && (
          <Notice
            title={noticeInfo.noticeMsg.title}
            message={noticeInfo.noticeMsg.message}
						link={noticeInfo.noticeMsg.link}
						linkText={noticeInfo.noticeMsg.linkText}
          />
        )}
      <div style={{width: dimensions?.width - 300}}>
        {/* <Marquee className="" style={{width: dimensions.width - ticker_width_space}} autoFill="true" delay="0" loop={0}> */}
				<EventTicker speed={80} items={items} width={dimensions?.width}/>

      </div>

      <HomepageVideo firebase={firebase} />

      <ContentSection />
      <Footer />
      {noticeInfo.infoModal && noticeInfo.infoModal.showInfoModal && (
        <InfoModal
          show={noticeInfo.infoModal.showInfoModal}
          hide={() => {
            setShowInfoModal(false);
          }}
          data={noticeInfo.infoModal}
        />
      )}
    </div>
  );
};

const HomepageVideo = ({ firebase }) => {
  const history = useHistory();

  return (
    <div className="videoContainer">
      <div className="overlay">
        <div className="overlay-msg d-flex align-items-center">
          <h1 className="title">GET IN THE BUNKER!</h1>
          {/* <p className="subtitle">
            We are the premier indoor golf environment, for the
            ultimate golf experience.
          </p> */}
          <p className="desc subtitle">
            LUXURY SPORTS BAR ● INDOOR GOLF ● FOOD ● MUSIC ● LOUNGE
          </p>
          <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center text-nowrap">
            <BookNowButton
              style="w-100"
              url="https://thebunker-teefindr-live.web.app/location/thebunker"
            />
            <BookEventsButton style="w-100" />
            <LocationSelector style="w-100" firebase={firebase} />
          </div>
        </div>
      </div>
      <video autoPlay loop muted playsInline id="video">
        <source
          src={
            window.innerWidth < 768
              ? TheBunkerHomePageVideoMobile
              : TheBunkerHomePageVideo
          }
          type="video/mp4"
        ></source>
      </video>
    </div>
  );
};

const ContentSection = () => {
  const history = useHistory();

  return (
    <div className="d-flex trackmanContainer p-0 m-0">
      <div className="trackmanContainer m-0 p-0">
        <div className="trackmanTitle">
          <h1 className="title mx-2">TAKE A TOUR</h1>
          <p
            className="text-muted m-0 mx-2 text-center"
            style={{ fontSize: 16 }}
          >
            Click the <strong>play</strong> button, then click the{' '}
            <strong>person</strong> icon to begin your tour!
          </p>
        </div>

        <div
          className="d-flex flex-wrap justify-content-center m-0 p-0"
          style={{ minHeight: 480 }}
        >
          <div className="d-flex col-12 col-xl-6 p-0 position-relative">
            <iframe
              style={{ zIndex: 1 }}
              width="100%"
              height="480px"
              src="https://mpembed.com/show/?m=u9BpmnnH69C&mpu=1854"
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>
          </div>
          <div className="d-flex col-12 col-xl-6 p-0 position-relative">
            <iframe
              style={{ zIndex: 1 }}
              width="100%"
              height="480px"
              src="https://mpembed.com/show/?m=fma1tdkHirm&mpu=1854"
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>
          </div>
          <div className="d-flex col-12 col-xl-6 p-0 position-relative">
            <iframe
              style={{ zIndex: 1 }}
              width="100%"
              height="480px"
              src="https://mpembed.com/show/?m=uzLoCgELmYD&mpu=1854"
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>

            <div className="m-0 p-4 pb-4 position-absolute d-flex w-100 h-100 justify-content-center align-items-end">
              <MealeoButton
                title="ORDER TAKEOUT/DELIVERY"
                url="https://order.getinthebunker.menu/r/67290"
                style={{ zIndex: 2 }}
              />
            </div>
          </div>

          <div className="d-flex col-12 col-xl-6 p-0 position-relative">
            <iframe
              style={{ zIndex: 1 }}
              width="100%"
              height="480px"
              src="https://mpembed.com/show/?m=EycycPF8DQT&mpu=1854"
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>

            <div className="m-0 p-4 pb-4 position-absolute d-flex w-100 h-100 justify-content-center align-items-end">
              <MealeoButton
                title="ORDER TAKEOUT/DELIVERY"
                url="https://order.getinthebunker.menu/r/67282"
                style={{ zIndex: 2 }}
              />
            </div>
          </div>
          <div className="d-flex col-12 col-xl-6 p-0 position-relative">
            <iframe
              width="100%"
              height="480px"
              src="https://mpembed.com/show/?m=A8QBTuwjiYY&mpu=1854"
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>
          </div>
          <div className="d-flex col-12 col-xl-6 p-0 position-relative">
            <iframe
              style={{ zIndex: 1 }}
              width="100%"
              height="480px"
              src="https://mpembed.com/show/?m=WV5KC6JHdfV&mpu=1854"
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>
          </div>
        </div>

        <div className="trackmanTitle">
          <h1 className="title">WHY TRACKMAN?</h1>
        </div>

        <div className="row m-0">
          <div className="col-12 col-xl-6 m-0 px-3">
            <img
              src="https://storage.googleapis.com/thebunker-assets/thebunker/CP_VIP_League.jpg"
              width={'100%'}
            />
          </div>
          <div className="col-12 col-xl-6 trackman-title px-4">
            <h2 className="trackman-subtitle">
              IT'S NOT LIKE THE GAME,
              <br />
              IT IS THE GAME.
            </h2>
            <p className="">
              The Bunker uses the world's foremost golf simulator:
              TRACKMAN. The TRACKMAN simulator is used by virtually
              all PGA professionals. Golfers at the Bunker are using
              the exact same tool that helps propel professional
              golfers to the top of their game, including Brooks
              Koepka, Lexi Thompson, and Tiger Woods.
            </p>
          </div>
        </div>

        <div className="row m-0 p-0">
          <div className="col-12 col-md-4 m-0 p-3">
            <div className="overlay-container">
              <div className="overlay">
                <p>
                  BREATHTAKING <br /> GAME EXPERIENCE
                </p>
              </div>
              <img
                src="https://storage.googleapis.com/thebunker-assets/thebunker/Holzhausern Driving Range.jpg"
                className="cover"
                width={'100%'}
                height={'100%'}
              />
            </div>
          </div>

          <div className="col-12 col-md-4 m-0 p-3 w-100">
            <div className="overlay-container">
              <div className="overlay">
                <p>
                  SECOND-TO-NONE <br /> FUNCTIONALITY
                </p>
              </div>
              <img
                src="https://storage.googleapis.com/thebunker-assets/thebunker/trackman.jpg"
                width={'100%'}
              />
            </div>
          </div>
          <div className="col-12 col-md-4 m-0 p-3 w-100">
            <div className="overlay-container">
              <div className="overlay">
                <p>
                  A GLOBAL <br /> GOLF EXPERIENCE{' '}
                </p>
              </div>
              <img
                src="https://storage.googleapis.com/thebunker-assets/thebunker/Golf Sim Patron 2.jpg"
                width={'100%'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="m-0 p-0 lets-play">
        <div className="overlay justify-content-center mx-0 px-0 bg-clear">
          <h1 className="title text-black">LET'S PLAY!</h1>
          <p className="subtitle text-black">
            Book your tee time now at one of our locations.
          </p>
          <div className="d-flex justify-content-center">
            <BookNowButton url="https://thebunker-teefindr-live.web.app/location/thebunker" />
            <BookEventsButton />
          </div>
        </div>
        <img
          src="https://storage.googleapis.com/thebunker-assets/thebunker/bunker-web-golf-texture.jpg"
          className="section-img"
        />
      </div>
    </div>
  );
};
export default withFirebase(Landing);
